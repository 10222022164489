<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'posModifierEdit', action: null })" class="pos-modifier-editor" :okText="translations.txtGenericSave" @ok="saveChanges"
    preventOKClose size="md" headerClass="px-3">

    <template v-slot:modal-header>
      <b-row v-if="modifier?.id">
        <b-col cols="12">
          <h5 class="modal-title mb-2">
            {{ modifier?.title }} ({{ translations.groupPOSModifierTypes[modifier?.mapping?.type] }})
          </h5>
        </b-col>
        <b-col cols="12" class="d-flex justify-content-between">
          <b-form-radio-group size="sm" style="margin-left: 5px;" buttons v-model="selectedEditorTab" button-variant="outline-primary">
            <b-form-radio :value="0"> {{ translations.txtPOSModifierEdit }} </b-form-radio>
            <b-form-radio :value="1"> {{ translations.txtPOSModifierUsedWith }} </b-form-radio>
          </b-form-radio-group>
          <b-button :variant="modifier.archived ? 'success' : 'danger'" size="sm" @click="save({ type: 'archive', value: !modifier.archived })">
            {{ modifier.archived ? translations.txtGenericRestore : translations.txtGenericArchive }}
          </b-button>
        </b-col>
      </b-row>
    </template>

  <wiskInputGroup v-if="modifier && selectedEditorTab === 0" style="min-height: 450px;">
    <wiskInput v-model="modifier.title" :label="translations.txtGenericTitle" readonly />
    <wiskInput v-model="modifier.plu_number" label="PLU Number" readonly />

    <b-row>
      <b-col>
        <wiskSelect v-model="mappingType" :items="posModifierMappingTypes" :label="translations.txtPOSModifierMappingType" @change="onTypeChange" required class="mb-0" />
      </b-col>
    </b-row>
    <b-row v-for="(command, index) in commands" :key="index">
      <hr class="my-3" />
      <b-col :cols="command.type === 'multiplier' ? 6 : 12">
        <wiskSelect v-model="command.type" :items="subTypes" :label="translations.txtPOSModifierMappingSubtype" @change="command.value = null;" required class="mb-1" />
      </b-col>
      <b-col :cols="command.type === 'multiplier' ? 6 : 12">
        <template v-if="mappingType === 'ingredient_change'">
          <addIngredient v-if="!command.value?.ingredient" vertical @add="command.value = $event.value;" :hideServingSize="command.type === 'remove'"
            :btAddLabel="translations.txtPOSModifierApplyChange" compact />
          <itemViewMini v-else :item="getIngredientItem(command.value.ingredient.value)">
            <div v-if="command.type !== 'remove'" class="w-100 pt-4 text-center">
              {{ getMeasurementDisplayString(command.value.serving_size.measurement) }}
            </div>
            <b-button variant="link" @click="command.value = null;" class="position-absolute" style="top: 0; right: 0;" v-tooltip="translations.txtGenericRemove">
              <icon name="wisk-trash" class="text-danger" :scale="0.9" />
            </b-button>
          </itemViewMini>
          <b-button v-if="index" variant="link" size="md" title="Remove" @click="modifier?.mapping?.value.splice(index, 1)" class="mt-2">
            <icon class="text-danger" name="wisk-minus" :scale="1" />
          </b-button>
          <b-button v-if="index === modifier?.mapping?.value.length - 1" variant="link" size="md" title="Add" @click="modifier?.mapping?.value.push({ type: '', value: {} })" class="mt-2">
            <icon class="" name="wisk-plus" :scale="1" />
          </b-button>
        </template>

        <template v-if="command.type === 'base_change'">
          <addIngredient v-if="!command.value?.serving_size" vertical @add="command.value = $event.value;" hideIngredient
            :btAddLabel="translations.txtPOSModifierApplyChange" compact />
          <b-alert v-else variant="secondary" show class="text-center mt-2">
            {{ getMeasurementDisplayString(command.value.serving_size.measurement) }}
            <b-button variant="link" @click="command.value = null" class="position-absolute" style="top: 0; right: 0;" v-tooltip="translations.txtGenericRemove">
              <icon name="wisk-trash" class="text-danger" :scale="0.9" />
            </b-button>
          </b-alert>
        </template>

        <b-row v-if="command.type === 'multiplier'">
          <b-col>
            <wiskInput v-model="command.value" :label="translations.txtPOSModifierMultiplier" inputType="number" autofocus triggerINputOnSet/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-button v-if="mappingType === 'ingredient_change' && !modifier?.mapping?.value?.length" variant="link" size="md" title="Add" @click="modifier?.mapping?.value.push({ type: '', value: {} })" class="mt-2">
      <icon class="" name="wisk-plus" :scale="1" />
    </b-button>
  </wiskInputGroup>
  <div v-if="selectedEditorTab === 1" style="min-height: 450px;">
    <itemUsageGrid :item="usedWith" />
  </div>

  <wiskLoading :loading="loading" />
  </wiskModal>

</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import isEqual from 'lodash.isequal'
import merge from 'lodash.merge'
import api from '@/api'
import { /*getPOSItemIngredientDescription, */getMeasurementDisplayString } from '@/modules/utils'
import addIngredient from '@/components/common/IngredientAdd'
import itemViewMini from '@/components/bottles/ItemViewMini'
import itemUsageGrid from '@/components/bottles/ItemUsageGrid'

export default {
  name: 'POSModifierEdit',
  components: { addIngredient, itemViewMini, itemUsageGrid },
  props: {
    editAction: Object
  },
  data() {
    return {
      modifier: null,
      mappingType: null,
      subTypes: [],
      selectedEditorTab: 0,
      usedWith: {},
      loading: false
    }
  },
  computed: {
    ...mapState(['translations', 'posModifiersById', 'bottlesById', 'subrecipesById']),
    ...mapGetters(['posModifierMappingTypes']),
    commands() {
      if (this.modifier) {
        if (this.mappingType === 'not_mapped' || this.mappingType === 'label') {
          return []
        }
        return this.mappingType === 'ingredient_change' ? this.modifier?.mapping?.value : this.modifier?.mapping?.value ? [this.modifier?.mapping?.value] : []
      }
      return []
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    getMeasurementDisplayString,
    getIngredientItem(ingredient) {
      let id = ingredient?.item_id || ingredient?.subrecipe_id,
        found = ingredient?.item_id ? this.bottlesById[id] : this.subrecipesById[id]

      return found ? { ...found, titleSuffix: found?.titleSuffix || '' } : null
    },
    onTypeChange({ id, subTypes }) {
      let modifier = merge({}, this.modifier)
      this.subTypes = subTypes ? subTypes : []
      if (id === 'ingredient_change') {
        modifier.mapping.value = []
      } else if (id === 'measurement_change') {
        modifier.mapping.value = {
          type: 'multiplier',
          value: 1
        }
      } else {
        modifier.mapping.value = {}
      }
      this.modifier = { ...modifier }
    },
    saveChanges() {
      const payload = {
        ...this.modifier?.mapping,
        type: this.mappingType,
      }
      this.save({ type: 'mapping', value: payload })
    },
    save(operations) {
      this.loading = true
      api.updatePOSModifier(this.modifier?.id, operations)
        .then(() => {
          this.loading = false
          this.setGlobalAction({ type: 'posModifierEdit', action: null })
        })
        .catch(() => {
          this.loading = false
        })
    }
  },
  created() {},
  watch: {
    editAction: {
      immediate: true,
      deep: true,
      handler(editAction) {
        if (editAction?.id) {
          this.modifier = merge({}, this.posModifiersById[editAction.id])
          this.mappingType = this.modifier?.mapping?.type
          this.subTypes = this.posModifierMappingTypes.find(({ id }) => id === this.mappingType)?.subTypes || []
          api.getPOSModifierUsedWith(this.modifier.id).then(data => {
            this.usedWith = {
              used_by: {
                pos_items: data.pos_item_ids
              }
            }
          })
        } else {
          this.modifier = { id: 0, title: this.translations.txtPOSModifierNew, mapping: { type: 'not_mapped' } }
        }
      }
    },
    posModifiersById() {
      if (this.modifier?.id && !isEqual(this.modifier, this.posModifiersById[this.modifier.id])) {
        this.modifier = merge({}, this.posModifiersById[this.modifier.id])
      }
    }
  }
}
</script>

<style lang="scss">
</style>
