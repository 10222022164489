<template>
  <b-container fluid class="movement-item-add p-0" v-if="configDataLoaded">

    <wiskInputGroup :disabled="disabled" class="row w-100 p-0 my-0 mx-n1" @errorCountChanged="setValidState" :key="key">
      <b-col class="px-1" cols="12" md="1">
        <imageView :src="imgSrc" v-if="imgSrc" class="align-self-md-start" />
        <div v-if="!item" class="align-self-start mb-2 float-lg-right">
          <b-dropdown :disabled="!!(item && item.item_id)" :text="translations.txtGenericNew" size="sm" variant="outline-primary" toggle-class="px3 py-1 mt-1 m55r-1" class="">
            <b-dropdown-item @click="createNewItem">
              {{ translations.txtGenericBottle }}
            </b-dropdown-item>
            <b-dropdown-item @click="createNewItemVariant">
              {{ translations.txtVenueBottlesVariant }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
      <b-col class="px-1" cols="12" md="10">
        <b-row class="flex-grow-1 mx-n1">
          <b-col class="px-1" cols="12" md="6">
            <wiskInput class="mb-lg-0" infoTooltipKey="4a2941a9-ad68-4949-ab9c-3780f6aab71d" autocompleteOpenAbove autofocus :modelValue="itemTitle" :label="translations.txtGenericAddItem"
              :placeholder="translations.txtGenericTypeToSearch" @autocompleteInput="onAutocompleteInput" @autocompleteSelected="onAutocompleteSelected" key="4a2941a9-ad68-4949-ab9c-3780f6aab71d"
              :autocompleteMinChars="1" :autocompleteItemFormatter="addSuffix" autocompleteDisplayKey="title" :suffix="(item && item.titleSuffix) || ''" emitFast
              :autocompleteItems="autocompleteItems" required autocomplete @clear="onAutocompleteSelected(null)" :autocompleteItemExists="!!item" no-gutters :fit-children="!!item" :fit-children-cols="1">
              <b-button v-if="item && item.item_id" size="sm" variant="link" @click="setGlobalAction({ type: 'itemEdit', action: { item_id: item.item_id, variantId: item.item_distributor_id } })" class="h-100">
                <icon class="text-primary" name="wisk-edit"></icon>
              </b-button>
              <template v-slot:autocompleteItemTemplate="{ autocompleteItem }">
                <imageView :src="autocompleteItem.image" v-if="autocompleteItem.image" style="position: absolute; left: 10px; top: 10px;" filterDisplay="tinyThumb" />
                <div class="fill-width-height" :style="{ 'padding-left': autocompleteItem.image ? '40px' : '' }">
                  <div class="fill-width-height">
                    {{ addSuffix(autocompleteItem.title, autocompleteItem) }}
                  </div>
                  <div class="fill-width-height" v-if="autocompleteItem.distributor && autocompleteItem.distributor.title">
                    <small>
                      <span style=""> {{ translations.txtGenericDistributor }} </span> &nbsp;
                      <span style="font-weight: bold;"> {{ autocompleteItem.distributor.title }} </span>
                    </small>
                    <small v-if="autocompleteItem.distributor_code">
                      , &nbsp;
                      <span style=""> {{ translations.txtGenericCode }} </span> &nbsp;
                      <span style="font-weight: bold;"> {{ autocompleteItem.distributor_code }} </span>
                    </small>
                  </div>
                  <div class="fill-width-height" v-else>
                    &nbsp;
                  </div>
                </div>
              </template>
            </wiskInput>
          </b-col>

          <b-col class="px-1" cols="12" md="2">
            <wiskSelect class="mb-2 mb-ms-0" infoTooltipKey="6926bca9-cdff-4b9d-9b2f-7d816cbf2a93" :items="measurementTypes" :disabled="!item" required v-model="entryType" :label="translations.txtGenericMeasurement" triggerInputOnLoad />
          </b-col>

          <b-col class="px-1" cols="12" md="4">
            <inventoryEntryInput v-if="entryType" class="mb-0" :inputType="entryType" :modelValue="measurement" @update:modelValue="measurement = $event" :item="item" horizontal emitFast
              @valid="inventoryEntryInputValid = $event" :key="entryType" />
          </b-col>

          <b-col class="px-1" cols="12" md="8" lg="6">
            <priceEditor class="mb-0" :item="item" :caseSize="(item && item.case_size) || 1" v-model="selectedPrice" inline horizontal emitFast
              :discountAvailable="movementType === 'intake' || movementType === 'transfer_out'" :key="priceEditorKey" @valid="priceEditorValid = $event" :disabled="movementType === 'adjustment'" />
          </b-col>

          <b-col class="px-1" cols="12" md="4" lg="3">
            <wiskSelect v-if="venue.accounting_enabled" class="mb-2 mb-ms-0" infoTooltipKey="488e0451-4d44-48dc-a226-32b1ef7639f7" :items="activeGLAccounts" :addNewItem="addGLAccountConfig"
              :multiselectOptions="{ maxHeight: 250, maxWidth: 450 }" :label="translations.txtGLAccount" v-model="glAccountId" />
          </b-col>
          <b-col v-if="movementType !== 'adjustment' && currentPermissionsByType.tax_rate_assign && venue.taxes_enabled" class="px-1" cols="12" md="4" lg="3">
            <wiskSelect class="mb-2 mb-ms-0" infoTooltipKey="1031f2b6-72ec-426f-80a7-8e7843dec356" :items="activeTaxes" :addNewItem="addTaxConfig"
              :multiselectOptions="{ maxHeight: 250, maxWidth: 450 }" :label="translations.txtTax" v-model="taxId" />
          </b-col>
          <b-col class="px-1" cols="12" md="4" lg="3" v-if="venue?.accounting_integration?.is_using_classes && venue.accounting_integration?.type === 'quickbooks'">
            <wiskSelect class="mb-2 mb-ms-0" infoTooltipKey="ca158e31-eeda-4d2f-adc9-bd13f9ca6b42" :items="accountingVenueClasses"
              :multiselectOptions="{ maxHeight: 250, maxWidth: 450 }" :label="translations.txtMovementAccountingQuickbooksClass" v-model="accountingClassId" />
          </b-col>

        </b-row>

      </b-col>
      <b-col class="px-1" cols="12" md="1">
        <b-button :disabled="!validComputed" size="sm" class="text-primary align-self-end float-end mb-sm-3 border-0 mt-auto" variant="link" @click="emitAddOperation" :title="translations.txtGenericAdd" ref="buttonAdd">
          <span style="font-size: 16px;"> {{ translations.txtGenericAdd }} </span>
          <icon style="margin-top: -8px;" name="wisk-check" :scale="0.9" class="ms-1" />
        </b-button>
      </b-col>

      <wiskInput class="d-none" label="itemId" required :modelValue="itemId" inputType="number" triggerInputOnLoad />

      <div class="d-none" v-if="measurement">
        <wiskInput label="measurement.quantity" required :modelValue="measurement.quantity" inputType="number" triggerInputOnLoad />
      </div>
      <div class="d-none" v-if="entryType === 'manual'">
        <wiskInput label="measurement.unit_of_measurement" required :modelValue="!!(measurement && measurement.unit_of_measurement)" inputType="checkbox" triggerInputOnLoad />
      </div>

    </wiskInputGroup>

    <wiskModal v-model="searchOnlineVisible" size="lg" hideOK hideFooter :title="onlineItemSearchTitle" extraLarge>
      <onlineItemSearch v-model="onlineItem" v-if="searchOnlineVisible" :startQuery="searchOnlineQuery" :distributor="distributorForSearchOnline" />
    </wiskModal>
  </b-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { getStringForSearchRecursive, stringFilter, prepareVariantIdAsItem, prepareVariantAsItem } from '@/modules/utils'
import imageView from '@/components/common/ImageView'
import onlineItemSearch from '@/components/common/OnlineItemSearch'
import inventoryEntryInput from '@/components/inventories/InventoryEntryInput'
import priceEditor from '@/components/bottles/ItemPrice'

export default {
  name: 'MovementItemAdd',
  emits: ['add'],
  components: { imageView, onlineItemSearch, inventoryEntryInput, priceEditor },
  props: { value: null, movementId: Number, movementType: String, disabled: Boolean, distributor: Object },
  data() {
    return {
      formValid: true,
      inventoryEntryInputValid: true,
      priceEditorValid: true,
      key: 1,
      priceEditorKey: 1,
      query: '',
      searchOnlineButtonVisible: false,
      searchOnlineVisible: false,
      searchOnlineQuery: '',
      entryType: 'unit',
      measurement: null,
      glAccountId: null,
      taxId: null,
      accountingClassId: null,
      item: null,
      itemTitle: '',
      selectedPrice: {},
      selectedDepositFee: 0,
      itemId: 0,
      onlineItem: null,
      itemCreationTimeStamp: null,
      autocompleteItems: []
    }
  },
  computed: {
    ...mapState(['translations', 'bottlesById', 'itemVariantsById', 'itemVariants', 'categoriesById', 'familiesById', 'distributorPricesById', 'currentPermissionsByType', 'itemVariantPricesById', 'accountingVenueClasses']),
    ...mapGetters(['currency', 'measurementsByType', 'activeGLAccounts', 'venue', 'configDataLoaded', 'activeTaxes']),
    measurementTypes() {
      let arr = [{ id: 'unit', title: 'unit' }]

      if (this.item?.case_size && this.item.case_size > 1) {
        arr.push({ id: 'case', title: 'case' })
      }
      if (this.item?.measurement && (this.item.measurement.type === 'weight' || this.item.measurement.type === 'volume')) {
        arr.push({ id: 'manual', title: 'manual' })
      }
      if (this.movementType === 'adjustment' && ['unit', 'each'].includes(this.item?.measurement?.type) && this.item?.measurement?.quantity > 1) {
        arr.push({ id: 'partial', title: 'partial' })
      }

      return arr
    },
    itemsByVariation() {
      return this.itemVariants.map(v => {
        let item = prepareVariantAsItem(v, this.$store.state)

        item.search_field = getStringForSearchRecursive({ payload: item, stopAtLevel: 1 })
        return item
      })
    },
    onlineItemSearchTitle() {
      if (this.distributor?.title && this.distributor?.link_with_venue_id) {
        return this.translations.translate('tplOnlineItemSearchDistributor', { '{a}': this.distributor.title })
      }

      return this.translations.txtOnlineItemSearchCatalog
    },
    distributorForSearchOnline() {
      if (this.distributor?.link_with_venue_id) {
        return this.distributor
      }
      return null
    },
    imgSrc() {
      return this.item?.image || ''
    },
    suffix() {
      return this.item.titleSuffix
    },
    addGLAccountConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'glAccountEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: glAccount => {
                callbackItemInjected(glAccount)
              }
            }
          })
        },
        label: this.translations.txtGLAccountNew
      }
    },
    addTaxConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'taxEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: tax => {
                callbackItemInjected(tax)
              }
            }
          })
        },
        label: this.translations.txtTaxesNew
      }
    },
    validComputed() {
      return this.formValid && this.priceEditorValid && this.inventoryEntryInputValid
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    setValidState(errorCount) {
      this.formValid = !errorCount

      setTimeout(() => {
        if (this.validComputed && this.$refs.buttonAdd) {
          this.$refs.buttonAdd.classList && this.$refs.buttonAdd.classList.add('attention')
        }
      }, 0)
    },
    createNewItem() {
      this.setGlobalAction({
        type: 'itemEdit',
        action: {
          item_id: 0,
          title: this.query,
          creationSource: { type: 'invoice', id: this.movementId },
          distributor_id: (this.distributor && this.distributor.id) || null,
          onChange: item => {
            this.itemCreationTimeStamp = new Date().getTime()
            setTimeout(() => {
              this.onAutocompleteSelected(prepareVariantIdAsItem(item.item_distributor_ids[0], this.$store.state))
            }, 2000) //TODO: fix this hack, maybe the API could make sure the item is on firestore before finishing the response
          }
        }
      })
    },
    createNewItemVariant() {
      this.setGlobalAction({
        type: 'itemSearchDialog',
        action: {
          splitItemsByVariant: false,
          onChange: item => {
            this.setGlobalAction({
              type: 'itemVariantEdit',
              action: {
                id: 0,
                itemId: item.item_id,
                itemVariant: {
                  distributor_id: (this.distributor && this.distributor.id) || null
                },
                onChange: result => {
                  this.itemCreationTimeStamp = new Date().getTime()

                  setTimeout(() => {
                    if (this.itemVariantsById[result.id]) {
                      this.onAutocompleteSelected(prepareVariantIdAsItem(result.id, this.$store.state))
                    } else {
                      setTimeout(() => {
                        this.onAutocompleteSelected(prepareVariantIdAsItem(result.id, this.$store.state))
                      }, 2000)
                    }
                  }, 2000)
                }
              }
            })
          }
        }
      })
    },
    onAutocompleteInput(value) {
      this.autocompleteItems = this.itemsByVariation.filter(b => !b.archived && b.variant && !b.variant.archived && stringFilter('contains', b.search_field, value))

      this.query = value
      this.searchOnlineQuery = value
    },
    onAutocompleteSelected(item) {
      this.selectedPrice = {}
      this.item = item
      if (item) {
        this.selectedDepositFee = this.venue.price_fee && item.variant ? item.variant.fee : 0
        let category = this.categoriesById[item.category_id] || {},
          family = (category && category.family_id && this.familiesById[category.family_id]) || {},
          variant = item.variant || {}

        item.distributor = item.distributor || {}

        if (this.currentPermissionsByType.gl_account_assign) {
          this.glAccountId = variant.gl_account_id || family.gl_account_id || item.distributor.gl_account_id || null
        }

        if (this.currentPermissionsByType.tax_rate_assign) {
          this.taxId = variant.tax_rate_id || item.distributor.tax_rate_id || category.tax_rate_id || family.tax_rate_id || null
        }

        if (this.venue.accounting_integration?.is_using_classes && this.venue.accounting_integration?.type === 'quickbooks') {
          this.accountingClassId = this.venue.accounting_integration?.default_venue_class_id
        }

        this.itemTitle = item.title
        this.autocompleteItems = []
        this.searchOnlineVisible = false
        this.searchOnlineButtonVisible = false
        this.entryType = ''
        this.itemId = (item && item.item_id) || 0

        this.entryType = item.order_format
        this.selectedPrice = (this.itemVariantPricesById[item.item_distributor_id] && this.itemVariantPricesById[item.item_distributor_id].price) || {}

        if (this.distributor && this.distributorPricesById[this.distributor.id] && this.distributorPricesById[this.distributor.id][item.item_distributor_id]) {
          let price = this.distributorPricesById[this.distributor.id][item.item_distributor_id] + item.fee
          if (price) {
            this.selectedPrice = { type: 'unit', value: price }
          }
        }

        this.priceEditorKey++
      } else {
        this.itemTitle = ''
        this.itemId = 0
        this.formValid = false
        this.measurement = null
        this.taxId = null
        this.accountingClassId = null
        this.glAccountId = null
        this.key++
      }
    },
    addSuffix(text, item) {
      return text + item.titleSuffix
    },
    emitAddOperation() {
      let operation = {}
      if (this.item?.item_id && this.selectedPrice) {
        operation = {
          item_id: this.item.item_id || 0,
          item_distributor_id: this.item.item_distributor_id || 0,
          sort_order: new Date().getTime(),
          gl_account_id: this.glAccountId,
          tax_rate_id: this.taxId,
          accounting_venue_class_id: this.accountingClassId,
          price: { ...this.selectedPrice },
          deposit_fee: this.selectedDepositFee,
          measurements: [
            {
              case_size: (this.item && this.item.case_size) || 0,
              type: this.entryType,
              unit_of_measurement: this.entryType,
              ...this.measurement
            }
          ]
        }
      }
      this.$emit('add', { type: 'item_add', value: operation })

      this.entryType = 'unit'
      this.measurement = {}
      this.selectedPrice = {}
      this.item = null
      this.itemTitle = ''
      this.itemId = 0
      this.taxId = null
      this.glAccountId = null

      setTimeout(() => {
        this.key++
      }, 100)
    }
  },
  watch: {
    entryType() {
      this.measurement = {}
    },
    itemVariantPricesById() {
      //the API has max 5 seconds to save the price of a new variant in the prices collection to be populated in the UI
      if (this.item && this.itemCreationTimeStamp + 5000 > new Date().getTime()) {
        this.onAutocompleteSelected(prepareVariantIdAsItem(this.item.item_distributor_id, this.$store.state))
      }
    },
    onlineItem(onlineItem) {
      if (onlineItem) {
        if (this.bottlesById[onlineItem.item_id]) {
          this.onAutocompleteSelected(this.bottlesById[onlineItem.item_id])
        } else {
          this.setGlobalAction({ type: 'itemEdit', action: { item: onlineItem, onChange: this.onAutocompleteSelected } })
          this.searchOnlineButtonVisible = false
        }
      }
    }
  }
}
</script>
<style lang="scss"></style>
